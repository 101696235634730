<script>
import { Checkbox, Icon, Popover } from 'ant-design-vue'

export default {
  name: 'area-select',
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: new Set(),
      disselected: new Set(),
      disabled: new Set(),
      selectRegions: [],
      disselectRegions: []
    }
  },
  watch: {
    options: {
      handler: function(val) {
        this.initData(val)
      },
      deep: true
    }
  },
  methods: {
    handleLevelChange(checked, data) {
      if (checked) {
        this.selected.add(data.id)
        this.disselected.delete(data.id)

        data.childList &&
          data.childList.forEach(child => {
            this.selected.add(child.id)
            this.disselected.delete(child.id)

            child.childList &&
              child.childList.forEach(j => {
                this.selected.add(j.id)
                this.disselected.delete(j.id)
              })
          })
      } else {
        this.selected.delete(data.id)
        this.disselected.add(data.id)

        data.childList &&
          data.childList.forEach(child => {
            this.selected.delete(child.id)
            this.disselected.add(child.id)

            child.childList &&
              child.childList.forEach(j => {
                this.selected.delete(j.id)
                this.disselected.add(j.id)
              })
          })
      }
    },
    onChange(e, data) {
      this.handleLevelChange(e.target.checked, data)
      this.$forceUpdate()
    },
    // 初始化数据
    initData(data) {
      data.forEach(first => {
        first.childList.forEach(second => {
          if (second.status !== 0) {
            this.selected.add(second.id)
          }

          if (second.status === 2) {
            this.disabled.add(second.id)
          }

          second.childList.forEach(third => {
            if (third.status !== 0) {
              this.selected.add(third.id)
            }

            if (third.status === 2) {
              this.disabled.add(third.id)
            }
          })
        })
      })
      this.$forceUpdate()
    },
    getData() {
      return {
        selectRegions: Array.from(this.selected)
          .map(id => ({ id }))
          // 过滤大区
          .filter(item => item.id.length !== 4)
          // 过滤禁用项
          .filter(item => Array.from(this.disabled).indexOf(item.id) === -1),
        disselectRegions: Array.from(this.disselected).map(id => ({ id }))
      }
    },
    getChecked(id) {
      return this.selected.has(id) || this.disabled.has(id)
    },
    getDisabled(status) {
      return status === 2
    },
    getIndeterminate(data) {
      const length = data.childList.filter(item => this.selected.has(item.id)).length

      return data.childList.length !== length && length > 0
    },
    renderItem(item) {
      return (
        <div class="row">
          <div class="first-level">
            <Checkbox
              checked={this.getChecked(item.id)}
              disabled={this.getDisabled(item.status)}
              onChange={e => this.onChange(e, item)}
            >
              {item.name}
            </Checkbox>
          </div>
          <div class="second-level-wrap">
            {item.childList.map(second => (
              <div class="second-level">
                <Checkbox
                  checked={this.getChecked(second.id)}
                  disabled={this.getDisabled(second.status)}
                  indeterminate={this.getIndeterminate(second)}
                  onChange={e => this.onChange(e, second)}
                >
                  {second.name}
                  <span class="number">({second.childList.length})</span>
                </Checkbox>
                <Popover trigger="click" overlayStyle={{ minWidth: '120px' }}>
                  <Icon type="caret-down" />
                  <div slot="content">
                    {second.childList.map(third => (
                      <div class="third-level">
                        <Checkbox
                          checked={this.getChecked(third.id)}
                          disabled={this.getDisabled(third.status)}
                          onChange={e => this.onChange(e, third)}
                        >
                          {third.name}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </Popover>
              </div>
            ))}
          </div>
        </div>
      )
    }
  },
  render() {
    return <div class="area-select">{this.options.map(item => this.renderItem(item))}</div>
  }
}
</script>

<style lang="less" scoped>
.area-select {
  .row {
    display: flex;

    .first-level {
      width: 100px;

      /deep/.ant-checkbox-wrapper {
        margin-left: 15px;
      }
    }

    .second-level-wrap {
      flex: 1;
      display: flex;
      flex-flow: row wrap;

      .second-level {
        width: 33%;
        margin-bottom: 10px;

        .number {
          color: #f0a2a2;
          font-style: italic;
        }
      }
    }
  }

  /deep/ .ant-checkbox-disabled + span {
    color: inherit;
  }
}
</style>
