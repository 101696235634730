<script>
import { Col, FormModel, Input, Row, Select, Spin, message } from 'ant-design-vue'
import api from '../../../command/api'
import AreaSelect from './AreaSelect'
import FormView from '../../../components/FormView'

export default {
  props: {
    freightDetailId: {
      type: [String, Number],
      default: ''
    },
    freightId: {
      type: [String, Number],
      default: ''
    },
    freightType: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      ...api.command.getState(),
      detail: {
        childType: ''
      },
      options: []
    }
  },
  computed: {
    isGlobal() {
      // 判断是否是全国记录
      return this.freightType === '1'
    }
  },
  mounted() {
    this.loading = true

    // 非全国记录请求区域数据
    if (!this.isGlobal) {
      api.freight.getProvinceList
        .call(this, {
          url: '/config/farmRegion/listProvince',
          params: {
            freightDetailId: this.freightDetailId,
            freightId: this.freightId,
            freightType: this.freightType
          }
        })
        .then(data => {
          this.options = data.map(e => {
            return {
              ...e,
              status: e.status ?? 0
            }
          })

          this.initData()
        })
    } else {
      this.initData()
    }
  },
  methods: {
    initData() {
      // 如果是已有记录，则请求详情
      if (this.freightDetailId) {
        api.command.getDetail
          .call(this, {
            url: '/config/farmFreightDetail/getDetail?id=' + this.freightDetailId
          })
          .then(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    sumbit() {
      let data = {
        selectRegions: [],
        disselectRegions: []
      }

      if (!this.isGlobal) {
        data = this.$refs.areaSelect.getData()

        if (data.selectRegions.length === 0) {
          return message.warning('请选择区域')
        }
      }

      // 如果是新增记录
      if (!this.freightDetailId) {
        this.detail.type = this.freightType
        this.detail.freightId = this.freightId
      }

      this.loading = true

      api.command.editPost
        .call(this, {
          url: `/config/farmFreightDetail/${this.freightDetailId ? 'update' : 'add'}`,
          isPost: false,
          params: {
            ...this.detail,
            ...data
          }
        })
        .then(() => {
          this.loading = false
          this.$emit('success')
        })
        .catch(() => {
          this.loading = false
        })
    },
    renderForm1() {
      const formData = [
        {
          name: '首件',
          key: 'firstCount',
          type: 'input',
          props: {
            addonAfter: '件'
          },
          cols: 12
        },
        {
          name: '运费',
          key: 'firstPrice',
          type: 'input',
          props: {
            addonAfter: '元'
          },
          cols: 12
        },
        {
          name: '增加件',
          key: 'otherCount',
          type: 'input',
          props: {
            addonAfter: '件'
          },
          cols: 12
        },
        {
          name: '运费',
          key: 'otherPrice',
          type: 'input',
          props: {
            addonAfter: '元'
          },
          cols: 12
        }
      ]

      return <FormView data={formData} form={this.detail} />
    },
    renderForm2() {
      const options = [
        {
          label: '金额',
          value: '1'
        },
        {
          label: '数量',
          value: '2'
        }
      ]

      const inputStyle = {
        width: '75%',
        margin: '0 5px'
      }

      return (
        <FormModel>
          <Row gutter={20}>
            <Col span={12}>
              <FormModel.Item label="包邮">
                <Select v-model={this.detail.childType} placeholder="请选择">
                  {options.map(option => (
                    <Select.Option value={option.value}>{option.label}</Select.Option>
                  ))}
                </Select>
              </FormModel.Item>
            </Col>
            <Col span={12}>
              {this.detail.childType !== '2' ? (
                <FormModel.Item label=" " colon={false}>
                  <span>满</span>
                  <Input v-model={this.detail.limitAmount} placeholder="价格" style={inputStyle} />
                  <span>元包邮</span>
                </FormModel.Item>
              ) : (
                <FormModel.Item label=" " colon={false}>
                  <span>满</span>
                  <Input v-model={this.detail.limitCount} placeholder="数量" style={inputStyle} />
                  <span>件包邮</span>
                </FormModel.Item>
              )}
            </Col>
          </Row>
        </FormModel>
      )
    }
  },
  render() {
    return (
      <div>
        <Spin spinning={this.loading}>
          <div class="top">{!this.isGlobal && <AreaSelect ref={'areaSelect'} options={this.options} />}</div>
          <div class="bottom">{this.freightType === '3' ? this.renderForm2() : this.renderForm1()}</div>
        </Spin>
      </div>
    )
  }
}
</script>
